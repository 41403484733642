<template>
  <div>
    <form autocomplete="off">
      <h3 class="font-weight-regular mt-10 mb-4">
        <v-icon left>mdi-account-outline</v-icon>
        Informações
      </h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-radio-group class="mt-0" hide-details="auto" v-model="form.person_type" row>
            <v-radio label="Pessoa Física" value="br_cpf" />
            <v-radio label="Pessoa Jurídica" value="br_cnpj" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>

        <template v-if="isJuridic">
          <v-col cols="12">
            <app-text-field v-model="form.responsible_name" label="Nome Responsável" />
          </v-col>
          <v-col cols="12" md="4">
            <app-text-field v-model="form.name" :label="labelName" />
          </v-col>
          <v-col cols="12" md="4">
            <app-text-field v-model="form.alias" label="Nome Fantasia" />
          </v-col>
          <v-col cols="12" md="4">
            <app-text-field v-model="form.cnpj" label="CNPJ" />
          </v-col>
        </template>

        <template v-if="!isJuridic">
          <v-col cols="12" md="6">
            <app-text-field v-model="form.name" :label="labelName" />
          </v-col>
          <v-col cols="12" md="6">
            <app-text-field v-model="form.cpf" label="CPF" />
          </v-col>
        </template>
        <v-col cols="12" md="6">
          <app-whatsapp-field v-model="form.phone" label="Telefone" />
        </v-col>
        <v-col cols="12" md="6">
          <app-text-field v-model="form.email" label="Email" />
        </v-col>
      </v-row>
      <h3 class="font-weight-regular mt-10 mb-4">
        <v-icon left>mdi-map-marker-radius-outline</v-icon> Endereço
      </h3>
      <v-row>
        <v-col cols="12" md="4">
          <app-cep-field v-model="form.main_address.postal_code" @address="setAddress($event)" />
        </v-col>
        <v-col cols="12" md="10">
          <app-text-field v-model="form.main_address.address" label="Endereço" />
        </v-col>
        <v-col cols="12" md="2">
          <app-text-field v-model="form.main_address.number" label=" Número" />
        </v-col>
        <v-col cols="12" md="6">
          <app-text-field v-model="form.main_address.complement" label="Complemento" />
        </v-col>
        <v-col cols="12" md="6">
          <app-text-field v-model="form.main_address.district" label="Bairro" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8" md="6">
          <app-text-field v-model="form.main_address.city" label="Cidade" disabled />
        </v-col>
        <v-col cols="4" md="6">
          <app-text-field v-model="form.main_address.state" label="Estado" disabled />
        </v-col>
      </v-row>
    </form>
  </div>
</template>


<script>
export default {
  props: {
    value: {},
    defaultVendor: {
      default: null,
    },
    updateVendorTo: {
      default: null,
    },
  },
  data() {
    return {
      baseForm: {
        vendor: null,
        name: null,
        email: null,
        phone: null,
        cpf: null,
        cnpj: null,
        person_type: "br_cpf",
        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city: null,
          state: null,
          type: "mainAddress",
        },
      },
      form: {},
    };
  },

  created() {
    this.reset();
    this.getBillingInformation();
  },

  watch: {
    value() {
      // this.setData();
    },

    form: {
      handler() {
        this.$emit("input", this.form);
      },

      deep: true,
    },
  },

  computed: {
    company() {
      if (this.$attrs.company) {
        return this.$attrs.company;
      } else {
        return this.$store.state.auth.company;
      }
    },

    isFree() {
      return this.company.is_free_account == 1;
    },

    user() {
      if (this.$attrs.user) {
        return this.$attrs.user;
      } else {
        return this.$store.state.auth.user;
      }
    },

    isInsert() {
      return !this.form.id;
    },
    isJuridic() {
      return this.form.person_type == "br_cnpj";
    },
    labelName() {
      return this.isJuridic ? "Razão Social" : "Nome";
    },
  },
  methods: {
    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.form.vendor = this.defaultVendor;

      if (this.isInsert) {
        this.setDataUser();
      }
    },

    setDataUser() {
      this.form.name = this.user.name;
      this.form.email = this.user.email;
      this.form.phone = this.user.phone_wa;

      // this.form
    },

    async getBillingInformation(id) {
      this.$loading.start();
      await this.$http
        .index("subscription/billing-information")
        .then((response) => {
          if (response.billing_information) {
            this.form = response.billing_information;
          }
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    setAddress(address) {
      if (address.city) {
        this.form.main_address.city = address.city;
      }
      if (address.address) {
        this.form.main_address.address = address.address;
      }
      if (address.district) {
        this.form.main_address.district = address.district;
      }
      if (address.state) {
        this.form.main_address.state = address.state;
      }
      if (address.city_code) {
        this.form.main_address.city_code = address.city_code;
      }
      if (address.postal_code) {
        this.form.main_address.postal_code = address.postal_code;
      }
    },
  },
};
</script>

<style></style>
